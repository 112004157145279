import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import getUserById from "../../lib/users/getUserById";
import { NATIONALITIES } from "../../helpers/nationalities";
import PageLoader from "../../components/PageLoader";
import getCampaigns from "../../lib/campaigns/getCampaigns";
import getInvestmentsByUserId from "../../lib/investments/getInvestmentsByUserId";
import CustomButton from "../../components/CustomButton";
import { downloadBlob } from "../../utils/downloadBlob";
import generatePDFContract from "../../utils/generatePDFContract";

export default function AutomatedContractPage() {
  const contractRef = useRef();
  const { userId } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const daysOfWeek = [
    "الأحد",
    "الأثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];
  const [firstInvestmentDay, setFirstInvestmentDay] = useState({
    day: "",
    date: "",
  });
  const [investorInfo, setInvestorInfo] = useState({});
  const [walletCampaigns, setWalletCampaigns] = useState([]);
  const [userApprovedInvestments, setUserApprovedInvestments] = useState([]);

  useEffect(() => {
    const fetchInvestorInfo = async () => {
      const user = await getUserById(userId);
      const campaigns = await getCampaigns();
      const userInvestments = await getInvestmentsByUserId(userId);
      const firstInvestment = userInvestments[userInvestments.length - 1];
      setFirstInvestmentDay({
        date: firstInvestment.createdAt.toDate().toISOString().split("T")[0],
        day: daysOfWeek[firstInvestment.createdAt.toDate().getDay()],
      });
      setInvestorInfo(user);
      setWalletCampaigns(campaigns);
      setUserApprovedInvestments(userInvestments);
      setIsPageLoading(false);
    };

    fetchInvestorInfo();
  }, [userId]);

  const totalInvestment = userApprovedInvestments.reduce(
    (total, investment) => {
      return (
        total + investment.requestedSharesNumber * investment.campaignSharePrice
      );
    },
    0
  );

  const totalWallet = walletCampaigns.reduce((total, campaign) => {
    return total + campaign.investmentMaxAmount;
  }, 0);

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <>
      <div
        className="text-center w-1/2 m-auto leading-loose bg-bg-color p-5"
        ref={contractRef}
      >
        <h1 className="text-2xl mb-16 text-text-1-color">عقد استثمار</h1>
        <div className="mb-5">
          <p className="mb-2">بعون الله تعالى وتوفيقه فإنه في مدينة الرياض</p>
          <p>
            يوم {firstInvestmentDay.day} بتاريخ {firstInvestmentDay.date} تم
            الإتفاق بين كل من:{" "}
          </p>
        </div>
        <hr className="mb-5 border border-ice-color-2 border-2 rounded-full" />
        {/* First */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div className="mb-10">
            <p className="mb-2">أولاً: شركة بيوت السحاب للعقارات</p>
            <p className="text-text-2-color">
              (شركة ذات مسؤولية محدودة شخص واحد) بموجب السجل التجاري رقم
              (4650269142)، وعنوانه مدينة الرياض، حي المونسية، المملكة العربية
              السعودية، ويمثلها الأستاذ/ معاذ إبراهيم صالح العبدالله المحيميد،
              هاتف رقم (966530104099+)، بريد إلكتروني (Muath@LGND-CO.com)، ويشار
              إليه فيما بعد ولأغراض هذا العقد بـ (الطرف الأول).
            </p>
          </div>
          <div>
            <p className="mb-2">ثانياً: {investorInfo.name}</p>
            <p className="text-text-2-color">
              {NATIONALITIES[investorInfo.nationality]} الجنسية، بموجب رقم
              الهوية {investorInfo.nationalId} وعنوانه المملكة العربية السعودية،
              وهاتف رقم <span dir="ltr">{investorInfo.phone}</span>، وبريد
              إلكتروني {investorInfo.emailAddress} ويشار إليه فيها بعد ولأغراض
              هذا العقد بـ (الطرف الثاني).
            </p>
          </div>
        </div>

        {/* Second */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div>
            <p className="mb-2">تمهيد:</p>
            <p className="text-text-2-color">
              يهدف هذا العقد الموقع إلى تنظيم العلاقة الاستثمارية بين أطرافه،
              ليكون حاكماً لعلاقتهما ومنظماً لها، ومرجعاً في حال الاختلاف، يملك
              الطرف الأول مؤسسة تعمل في مجال التأجير العقاري وإدارة مرافق
              الإيواء السياحي، ولكون الطرف الأول له الحق في تشغيل وتأجير مجموعة
              من العقارات، ولرغبة الطرف الأول افتتاح وتشغيل المباني وإدارتها،
              ولما له من خبرة في إدارة وتشغيل وتأجير مرافق الإيواء السياحي
              والقيام بكافة متطلبات هذا العمل، ولرغبة الطرف الثاني بالاستثمار في
              المحفظة العقارية للطرف الأول من خلال دفع مبلغ مقطوع للمساهمة في
              تغطية التكاليف الإدارية والتصنيعية والتشغيلية والتسويقية
              والقانونية، فقد اتفق طرفا هذا العقد وهما بكامل أهليتهما النظامية
              المعتبرة شرعاً ونظاماً على إبرام هذا العقد وفقاً للبنود التالية:
            </p>
          </div>
        </div>

        {/* Third */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div>
            <p className="mb-2">التعريفات:</p>
            <p className="text-text-2-color mb-4">
              المشروع العقاري: هي المنشأة التي تمتلك حق الانتفاع من العقار
              المذكور في عقد الايجار، بالإضافة إلى ملكية الأثاث والأجهزة
              الكهربائية داخل العقار وأي أصول أخرى تم شراؤها بنفس السجل التجاري
              وأي أصول إبداعية (الصور والفيديوهات) وأي أصول تقنية (أرقام، شرائح
              إنترنت، ايميلات).
            </p>
            <p className="text-text-2-color mb-4">
              المحفظة العقارية: هي مجموع المشاريع العقارية التي تمتلكها الشركة.
            </p>
            <p className="text-text-2-color mb-4">
              القيمة الكلية للمشروع: هي المبلغ الإجمالي الذي يتم استثماره في
              المشروع. تتضمن هذه القيمة التكاليف الإجمالية لبناء أو تطوير
              المشروع بما في ذلك التكاليف الإنشائية وأية تكاليف إضافية ذات صلة.
            </p>
            <p className="text-text-2-color mb-4">
              نسبة المستثمر: هي النسبة المئوية لحصة المستثمر في المحفظة وتنقص
              وتزيد حسب قيمة المحفظة.
            </p>
            <p className="text-text-2-color mb-4">
              التوزيعات: التوزيعات هي الأموال أو الأرباح التي يتم توزيعها على
              المستثمرين أو المساهمين في المشروع. وتمثل نصيب كل مستثمر من
              الأرباح الصافية بناءً على النسب المتفق عليها.
            </p>
            <p className="text-text-2-color mb-4">
              الملحقات: الملحق هو وثيقة تُضاف إلى العقد القانوني لتعديل شروطه أو
              إضافة شروط إضافية، وتكون ملزمة بنفس القوة القانونية كالعقد الأصلي.
            </p>
          </div>
        </div>

        {/* Fourth */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div>
            <p className="mb-2">المادة الأولى:</p>
            <p className="text-text-2-color mb-4">
              يعتبر التمهيد جزءً لا يتجزأ من العقد ومكملاً له، كما يعتبر أي ملحق
              موقع من الطرفين جزءًا منه.
            </p>
          </div>
        </div>

        {/* Fifth */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div>
            <p className="mb-2">المادة الثانية: وثائق العقد:</p>
            <p className="text-text-2-color">
              يتألف هذا العقد من الوثائق التالية:
            </p>
            <p className="text-text-2-color">1. عقد الإستثمار</p>
            <p className="text-text-2-color">2. وثيقة الإفصاح</p>
            <p className="text-text-2-color">
              وأي ملاحق أو ملاحظات ملحقة لهذا العقد سيعتبر جزءًا لا يتجزأ منه.
            </p>
          </div>
        </div>

        {/* Sixth */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div>
            <p className="mb-2">المادة الثالثة: محل العقد:</p>
            <p>محل العقد هو جميع عقارات المحفظة:</p>
            <div className="mb-5">
              {walletCampaigns.map((campaign, index) => (
                <p key={index} className="text-text-2-color">
                  - العقار الموصوف في عقد الإيجار الموحد رقم{" "}
                  {campaign.leaseAgreementNumber} بتاريخ{" "}
                  {campaign.createdAt.toDate().toISOString().split("T")[0]} (
                  {campaign.title})
                </p>
              ))}
            </div>
            <p>
              بالإضافة إلى جميع العقارات القادمة التي قد تضيفها الشركة للمحفظة
              حسب ما تراه مناسبًا أو حسب ماتمليه عليها الدراسات السوقية
            </p>
          </div>
        </div>

        {/* Seventh */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div>
            <p className="mb-2">
              المادة الرابعة: قيمة المشاريع العقارية ونسبة المستثمر:
            </p>
            <p className="text-text-2-color mb-4">
              1- يقر الطرف الثاني باستثماره بمبلغ قدره{" "}
              {totalInvestment.toLocaleString()} ريال سعودي في مشروع الطرف الأول
              وأن قيمة المشروع الحالية هي مجموعة كامل العقارات وهو مبلغ قدره{" "}
              {totalWallet.toLocaleString()} ريال سعودي.
            </p>
            <p className="text-text-2-color">
              2- بالتالي ما دفعه الطرف الثاني نسبة قدرها{" "}
              {((totalInvestment / totalWallet) * 100).toFixed(2)}% من قيمة
              المشروع كاملًا.
            </p>
          </div>
        </div>

        {/* Eighth */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div>
            <p className="mb-2">المادة الخامسة: المدة:</p>
            <p className="text-text-2-color mb-4">
              - مدة التعاقد تبدأ من تاريخ توقيع العقد وتمتد طوال مدة المشاريع
              القائمة في المحفظة.
            </p>
            <p className="text-text-2-color mb-4">
              - مدة التعاقد تبدأ من تاريخ بدء التأجير في المشاريع الجديدة.
              وتنتهي عند انتهاء عقد التأجير (8 سنوات) ما لم يتفق الأطراف على
              خلاف ذلك.
            </p>
            <p className="text-text-2-color mb-4">
              - في حالة الرغبة في التخارج، يتم تنفيذ ذلك من خلال عرض حصص الطرف
              الثاني الخارج للشركاء الآخرين للطرف الأول أولاً، وذلك وفقاً لحق
              الشفعة.
            </p>
            <p className="text-text-2-color mb-4">
              في حالة تنازل الشركاء الآخرين عن حق الشفعة، يحق للطرف الثاني
              الراغب في الانسحاب بيع حصصه لمستثمرين آخرين. ويمكن الاستعانة ببيع
              الحصص لمستثمرين آخرين بالطرف الأول، الذي يتولى مهمة إيجاد مستثمرين
              جدد وتسهيل عملية بيع الحصص بمقابل مالي يتم تحديده لاحقا بناءً على
              اتفاق بين الأطراف المعنية.
            </p>
            <p className="text-text-2-color">
              - وافق الطرف الثاني على تفويض الطرف الاول باستقطاع نسبة من العائد
              الاستثماري السنوي حسب ما يراه المكتب المالي مناسباً لإعادة
              استثمارها وتجديد المشروع نفسه في حال وافق مالك العقار على التجديد
              أو شراء حصص بمشروع من نفس فئة الدخل، وذلك حسب ما تمليه الدراسات
              السوقية وحسب ما يراه الطرف الأول مناسباً.
            </p>
          </div>
        </div>

        {/* Ninth */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div>
            <p className="mb-2">المادة السادسة: نطاق الاستثمار:</p>
            <p className="text-text-2-color">
              للطرف الأول الحق بتوسيع نطاق مشروع الاستثمار الحالي عن طريق إضافة
              عقارات جديدة وله حق الإدارة للمحفظة العقارية واختيار العقارات
              للمشاريع الجديدة حسب ما تمليه عليه الدراسات السوقية وكما يراه
              مناسباً.
            </p>
          </div>
        </div>

        {/* Tenth */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div>
            <p className="mb-2">المادة السابعة: إدارة المحفظة العقارية:</p>
            <p className="text-text-2-color mb-4">
              - يتعهد الطرف الأول بإدارة جميع الأنشطة المتعلقة بالمشروع بشكل
              كامل.
            </p>
            <p className="text-text-2-color mb-4">
              - يتحمل الطرف الأول جميع الأعباء القانونية والمسؤوليات المالية
              الناتجة عن إدارة المشروع.
            </p>
            <p className="text-text-2-color">
              - يتم إرسال تقارير دورية كل ربع للطرف الثاني بخصوص أداء المحفظة
              العقارية (ملخص الربع، تحليلات ونسب وأرقام المشروع)
            </p>
          </div>
        </div>

        {/* Eleventh */}
        <div className="rounded-xl bg-white p-10 text-start mb-5">
          <div>
            <p className="mb-2">المادة الثامنة: أحكام عامة:</p>
            <p className="text-text-2-color mb-4">
              - يخضع هذا العقد وتفسيره وتنفيذ وما تضمنه للأنظمة والإجراءات
              النظامية في المملكة العربية السعودية.
            </p>
            <p className="text-text-2-color">
              - يلجأ الطرفان إلى حل أي خلاف ناتج عن هذه الإتفاقية أو العقود
              اللاحقة عليها المبرمة بين الطرفين؛ بشكل ودي وإن تعذر الحل الودي؛
              فيحق لأي من الطرفين اللجوء للجهات القضائية في المملكة العربية
              السعودية للفصل في النزاع بين الطرفين.
            </p>
          </div>
        </div>

        {/* Twelvth */}
        <div className="rounded-xl bg-white p-10 text-start mb-10">
          <div>
            <p className="mb-2">المادة التاسعة: بنود العقد ونسخه:</p>
            <p className="text-text-2-color">
              حرر هذا العقد من تسعة مواد وملحقات إضافية ترسل عند إضافة أي مشروع
              جديد للمحفظة العقارية، وباللغة العربية واستلم كل طرف نسخة عبر
              عنوان البريد الإلكتروني المذكور في بيانات الأطراف أعلاه للتقيد بها
              والرجوع إليها عند الحاجة بعد الموافقة على مضمونه وقراءة ما فيه
              بدقة وفهمه واستيعاب معانيه ومقاصده استيعاباً نافياً للجهالة
              والغرر.
            </p>
          </div>
        </div>

        <hr className="mb-5 border border-ice-color-2 border-2 rounded-full" />

        <p className="mb-10">هذا وقد أذن لمن يشهد والله خير الشاهدين،،،</p>
      </div>
      <div className="w-1/3 m-auto">
        <CustomButton
          title="طباعة العقد"
          customStyle="p-4"
          handleClick={async () => {
            const pdfBlob = await generatePDFContract(contractRef.current);
            downloadBlob(pdfBlob, "contract.pdf");
          }}
        />
      </div>
    </>
  );
}
