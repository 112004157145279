import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import getLogsByCampaignId from "../../lib/pre_active_logs/getLogsByCampaignId";
import getUserById from "../../lib/users/getUserById";
import getCampaignById from "../../lib/campaigns/getCampaignById";
import PageLoader from "../../components/PageLoader";
import { formatDateNow } from "../../utils/formatDateNow";
import { exportExcelData } from "../../utils/exportExcelData";
import updateLog from "../../lib/pre_active_logs/updateLog";
import notify from "../../utils/notify";
import CustomLabel from "../../components/CustomLabel";
import PRE_ACTIVE_STATUS from "../../helpers/pre_active_log_status";
import createNotification from "../../lib/notifications/createNotification";
import { Timestamp } from "firebase/firestore";

export default function ReservationRecords() {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [preActiveLogs, setPreActiveLogs] = useState([]);
  const [filteredPreActiveLogs, setFilteredPreActiveLogs] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [usersLogs, setUsersLogs] = useState([]);
  const [currentCampaign, setCurrentCampaign] = useState({});
  const [currentLog, setCurrentLog] = useState({});
  const [totalRequestedShares, setTotalRequestedShares] = useState(0);
  const [totalReservationsAmount, setTotalReservationsAmount] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      // fetch logs
      const fetchedLogs = await getLogsByCampaignId(campaignId);
      const fetchedCampaign = await getCampaignById(campaignId);

      let tempUsers = [];
      let totalReq = 0;
      let totalResAmount = 0;

      for (let i = 0; i < fetchedLogs.length; i++) {
        var user = await getUserById(fetchedLogs[i].userId);
        totalReq += fetchedLogs[i].requestedSharesNumber;
        totalResAmount +=
          fetchedCampaign.sharePrice * fetchedLogs[i].requestedSharesNumber;
        tempUsers.push(user);
      }

      // assign data
      setUsersLogs(tempUsers);
      setPreActiveLogs(fetchedLogs);
      setFilteredPreActiveLogs(fetchedLogs);
      setCurrentCampaign(fetchedCampaign);
      setTotalRequestedShares(totalReq);
      setTotalReservationsAmount(totalResAmount);

      setIsPageLoading(false);
    };
    fetchData();
  }, [campaignId]);
  const tableHeaders = [
    "اسم المستخدم",
    "رقم الجوال",
    "عدد الوحدات المحجوزة",
    "قيمة الوحدة (ريال)",
    "قيمة حجز الإستثمار (ريال)",
    "تاريخ الحجز",
    "حالة الحجز",
    "ملاحظات للحجز",
    "إجراء",
  ];
  const handleOnExport = () => {
    // temp user data to map regions, nationalities, income
    let usersData = [];
    for (var i = 0; i < filteredPreActiveLogs.length; i++) {
      const userObj = {
        "اسم المستخدم": usersLogs[i].name,
        "رقم الجوال": usersLogs[i].phone,
        "عدد الوحدات المحجوزة": filteredPreActiveLogs[i].requestedSharesNumber,
        "قيمة الوحدة (ريال)": currentCampaign.sharePrice.toLocaleString(),
        "قيمة حجز الإستثمار (ريال)": (
          currentCampaign.sharePrice *
          filteredPreActiveLogs[i].requestedSharesNumber
        ).toLocaleString(),
        "تاريخ الحجز": filteredPreActiveLogs[i].createdAt
          .toDate()
          .toISOString()
          .split("T")[0],
        "حالة الحجز": PRE_ACTIVE_STATUS[filteredPreActiveLogs[i].status],
        "ملاحظات للحجز": filteredPreActiveLogs[i].notes,
      };
      usersData.push(userObj);
    }
    const now = formatDateNow();
    exportExcelData(usersData, "Reservations", `Reservations${now}.xlsx`);
  };

  const handleStatusUpdate = async (status) => {
    try {
      await updateLog(currentLog.id, { status: status });
      await createNotification({
        title: currentCampaign.title,
        message:
          status === "APPROVED"
            ? "تمت الموافقة على حجزك للفرصة ويمكنك الآن فتح التطبيق والإنضمام مباشرة"
            : "نعتذر منك ولكن تم رفض حجزك للفرصة، لمزيد من التفاصيل يمكنك التواصل معنا عبر قنوات التواصل في التطبيق",
        data: {
          type:
            status === "APPROVED"
              ? "ACCEPT_PREACTIVE"
              : "REJECT_PREACTIVE",
        },
        toUser: currentLog.userId,
        isRead: false,
        createdAt: Timestamp.now(),
      });
      navigate(0);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notify("ERROR", "حصل خطأ أثناء إجراء العملية.");
    }
  };

  const filterListByStatus = (status) => {
    setSelectedStatus(status);
    if (status === "ALL") {
      setFilteredPreActiveLogs(preActiveLogs);
      return;
    }
    const list = preActiveLogs.filter((item) => item.status === status);
    setFilteredPreActiveLogs(list);
  };
  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <Navbar />
      <div className="tablet:flex tablet:flex-row gap-10">
        <div className="bg-ice-color p-5 text-center rounded-lg mt-5 mb-10 w-full">
          <p className="text-text-1-color font-bold text-lg mb-5">
            عدد الحجوزات الكلي
          </p>
          <p className="text-primary-color text-2xl">{preActiveLogs.length}</p>
        </div>
        <div className="bg-ice-color p-5 text-center rounded-lg mt-5 mb-10 w-full">
          <p className="text-text-1-color font-bold text-lg mb-5">
            عدد الوحدات المحجوزة
          </p>
          <p className="text-primary-color text-2xl">{totalRequestedShares}</p>
        </div>
        <div className="bg-ice-color p-5 text-center rounded-lg mt-5 mb-10 w-full">
          <p className="text-text-1-color font-bold text-lg mb-5">
            قيمة الحجوزات (الإستثمارات)
          </p>
          <p className="text-primary-color text-2xl">
            {totalReservationsAmount.toLocaleString()} ريال
          </p>
        </div>
      </div>
      <PageTitle title={currentCampaign.title} />
      <div className="flex flex-row justify-between items-center mb-20 mt-10">
        <PageTitle title="سجل الحجوزات" isSubtitle={true} />
        {filteredPreActiveLogs.length > 0 ? (
          <div className="w-60">
            <CustomButton
              title="استخراج اكسل"
              customStyle="p-2 mb-5"
              handleClick={handleOnExport}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="grid mobile:grid-cols-2 tablet:grid-cols-4 mb-5 gap-5 w-full">
        <CustomButton
          title="الكل"
          type={selectedStatus === "ALL" ? "default" : "outline"}
          customStyle="p-3"
          handleClick={() => {
            filterListByStatus("ALL");
          }}
        />
        <CustomButton
          title="تم التأكيد"
          type={selectedStatus === "APPROVED" ? "success" : "success_outline"}
          customStyle="p-3"
          handleClick={() => {
            filterListByStatus("APPROVED");
          }}
        />
        <CustomButton
          title="بانتظار الموافقة"
          type={selectedStatus === "PENDING" ? "regular" : "regular_outline"}
          customStyle="p-3"
          handleClick={() => {
            filterListByStatus("PENDING");
          }}
        />
        <CustomButton
          title="مرفوض"
          type={selectedStatus === "REJECTED" ? "danger" : "danger_outline"}
          customStyle="p-3"
          handleClick={() => {
            filterListByStatus("REJECTED");
          }}
        />
      </div>

      <div className="overflow-x-auto">
        {filteredPreActiveLogs.length === 0 ? (
          <h1 className="text-2xl p-10 text-center">لا توجد نتائج</h1>
        ) : (
          <table className="w-full text-sm">
            <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
              <tr>
                {tableHeaders.map((header, index) => {
                  return (
                    <th scope="col" className="px-6 py-3" key={index}>
                      {header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="text-center">
              {filteredPreActiveLogs.map((column, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                  >
                    {
                      <>
                        <td className="px-6 py-4">{usersLogs[index].name}</td>
                        <td className="px-6 py-4" dir="ltr">
                          {usersLogs[index].phone}
                        </td>
                        <td className="px-6 py-4">
                          {column.requestedSharesNumber}
                        </td>
                        <td className="px-6 py-4">
                          {currentCampaign.sharePrice.toLocaleString()}
                        </td>
                        <td className="px-6 py-4">
                          {(
                            currentCampaign.sharePrice *
                            column.requestedSharesNumber
                          ).toLocaleString()}
                        </td>
                        <td className="px-6 py-4">
                          {
                            column.createdAt
                              .toDate()
                              .toISOString()
                              .split("T")[0]
                          }
                        </td>
                        <td className="px-6 py-4">
                          {column.status === "APPROVED" ? (
                            <CustomLabel
                              title={PRE_ACTIVE_STATUS[column.status]}
                              status="SUCCESS"
                            />
                          ) : column.status === "PENDING" ? (
                            <CustomLabel
                              title={PRE_ACTIVE_STATUS[column.status]}
                              status="REGULAR"
                            />
                          ) : column.status === "REJECTED" ? (
                            <CustomLabel
                              title={PRE_ACTIVE_STATUS[column.status]}
                              status="DANGER"
                            />
                          ) : (
                            " - "
                          )}
                        </td>
                        <td className="px-6 py-4">{column.notes}</td>
                        <td className="px-6 py-4">
                          {column.status === "PENDING" ? (
                            <div className="flex gap-3">
                              <CustomButton
                                title="تأكيد"
                                customStyle="py-2 px-5"
                                type="success"
                                isLoading={isLoading}
                                handleClick={() => {
                                  setIsLoading(true);
                                  setCurrentLog(column);
                                  handleStatusUpdate("APPROVED");
                                }}
                              />
                              <CustomButton
                                title="رفض"
                                customStyle="py-2 px-5"
                                type="danger"
                                isLoading={isLoading}
                                handleClick={() => {
                                  setIsLoading(true);
                                  setCurrentLog(column);
                                  handleStatusUpdate("REJECTED");
                                }}
                              />
                            </div>
                          ) : (
                            " - "
                          )}
                        </td>
                      </>
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
