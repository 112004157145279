import { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import CustomInput from "../../components/CustomInput";
import CustomTextArea from "../../components/CustomTextArea";
import getInvestments from "../../lib/investments/getInvestments";
import { useNavigate } from "react-router-dom";
import updateInvestment from "../../lib/investments/updateInvestment";
import getCampaigns from "../../lib/campaigns/getCampaigns";
import getUserByName from "../../lib/users/getUserByName";
import createInvestment from "../../lib/investments/createInvestment";
import { Timestamp } from "firebase/firestore";
import PageLoader from "../../components/PageLoader";
import notify from "../../utils/notify";
import { ToastContainer } from "react-toastify";
import CustomDropdown from "../../components/CustomDropdown";
import ImagePicker from "../../components/ImagePicker";
import uploadImage from "../../lib/firebase_storage/uploadImage";
import CustomLabel from "../../components/CustomLabel";
import INV_STATUS from "../../helpers/investment_status";
import walletIcon from "../../assets/icons/wallet.png";
import getSharedInfo from "../../lib/shared_info/getSharedInfo";
import updateSharedInfo from "../../lib/shared_info/updateSharedInfo";
import createNotification from "../../lib/notifications/createNotification";
import getUserById from "../../lib/users/getUserById";
import { ImagePreview } from "../../components/ImagePreview";
import generateId from "../../utils/generateId";
import Receipt from "../../components/Receipt";
import generatePDF from "../../utils/generatePDF";

export default function OpportunityInvestmentRecordsPage() {
  const navigate = useNavigate();
  // Investments
  const [selectedInvestment, setSelectedInvestment] = useState({});
  const [selectedInvestmentCampaign, setSelectedInvestmentCampaign] = useState(
    {}
  );
  const [investorName, setInvestorName] = useState("");
  const [requestedSharesNumber, setRequestedSharesNumber] = useState(0);
  const [investments, setInvestments] = useState([]);
  const [filteredInvestments, setFilteredInvestments] = useState([]);
  const [sharesPrice, setSharesPrice] = useState(0);
  const [transactionImgFile, setTransactionImgFile] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("ALL");

  // Campaigns
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [selectedSuggestedUser, setSelectedSuggestedUser] = useState({});

  // Modals
  const [showModal, setShowModal] = useState(false);
  const [showEditWalletModal, setShowEditWalletModal] = useState(false);
  const [showInvestmentModal, setShowInvestmentModal] = useState(false);
  const [showTransactionImgModal, setShowtransactionImgModal] = useState(false);

  // Loadings
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // EDIT WALLET INFO
  const [walletTotal, setWalletTotal] = useState(0);
  const [walletTotalWithoutHidden, setWalletTotalWithoutHidden] = useState(0);
  const [walletAnnualROI, setWalletAnnualROI] = useState("");
  const [walletDuration, setWalletDuration] = useState("");
  const [capitalRecoveryPeriod, setCapitalRecoveryPeriod] = useState("");
  const [sharedInfo, setSharedInfo] = useState({});

  // SHOW INVESTOR
  const [investor, setInvestor] = useState("");
  const [selectedSuggestedInvestor, setSelectedSuggestedInvestor] = useState(
    []
  );
  const [investorPhoneNumber, setInvestorPhoneNumber] = useState("");
  const [investorShareNumbers, setInvestorShareNumbers] = useState(0);
  const [investorSharePrice, setInvestorSharePrice] = useState(0);
  const [investorNotes, setInvestorNotes] = useState("");
  const [investorTransactionImgFile, setInvestorTransactionImgFile] =
    useState("");
  const [investorTransactionURL, setInvestorTransactionURL] = useState("");

  useEffect(() => {
    const fetchInvestments = async () => {
      setIsPageLoading(true);
      const investments = await getInvestments();
      setInvestments(investments);
      setFilteredInvestments(investments);
      setIsPageLoading(false);
    };

    const fetchSharedInfo = async () => {
      setIsPageLoading(true);

      const sharedInfo = await getSharedInfo();
      setSharedInfo({
        walletAnnualROI: sharedInfo.walletAnnualROI,
        walletDuration: sharedInfo.walletDuration,
        capitalRecoveryPeriod: sharedInfo.capitalRecoveryPeriod,
      });
      setWalletAnnualROI(sharedInfo.walletAnnualROI);
      setWalletDuration(sharedInfo.walletDuration);
      setCapitalRecoveryPeriod(sharedInfo.capitalRecoveryPeriod);
      setIsPageLoading(false);
    };

    const fetchCampaign = async () => {
      setIsPageLoading(true);
      const campaigns = await getCampaigns();
      //Calculate wallet total
      const notDeletedCampaigns = campaigns.filter(
        (cam) => cam.status !== "DELETED"
      );
      let notDeletedCampaignsTotal = 0;
      for (var i = 0; i < notDeletedCampaigns.length; i++) {
        notDeletedCampaignsTotal += notDeletedCampaigns[i].investmentMaxAmount;
      }
      const notDeletedNorHiddenCampaigns = campaigns.filter(
        (cam) => cam.status !== "DELETED" && cam.status !== "HIDDEN"
      );
      let notDeletedNorHiddenTotal = 0;
      for (var j = 0; j < notDeletedNorHiddenCampaigns.length; j++) {
        notDeletedNorHiddenTotal +=
          notDeletedNorHiddenCampaigns[j].investmentMaxAmount;
      }

      setWalletTotal(notDeletedCampaignsTotal);
      setWalletTotalWithoutHidden(notDeletedNorHiddenTotal);
      setCampaigns(campaigns);
      setIsPageLoading(false);
    };

    fetchSharedInfo();
    fetchInvestments();
    fetchCampaign();
  }, []);
  const tableHeaders = [
    "الاسم",
    "رقم الجوال",
    "قيمة الإستثمار",
    "عدد الحصص",
    "قيمة الحصة الواحدة",
    "حالة الإستثمار",
    "تاريخ الإستثمار",
    "إجراء",
  ];

  const handleInvestmentStatus = async (id, status) => {
    if (
      investor.length === 0 ||
      investorPhoneNumber.length === 0 ||
      investorShareNumbers === "" ||
      investorShareNumbers === 0
    ) {
      notify("WARNING", "الرجاء ملئ جميع الحقول.");
      setIsLoading(false);
      return;
    }
    if (investorTransactionImgFile === null && investorTransactionURL === "") {
      notify("WARNING", "قم بإضافة صورة للحوالة.");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      let updateData = {
        status: status,
        userFullName: investor,
        userPhone: investorPhoneNumber,
        userId: selectedSuggestedInvestor.id,
        userBankAccounts: selectedSuggestedInvestor.bankAccounts,
        requestedSharesNumber: Number(investorShareNumbers),
        notes: investorNotes,
        receiptURL: "",
        transactionURL: investorTransactionURL,
      };
      if (!investorTransactionURL) {
        const uniqueId = generateId();
        const transactionURL = await uploadImage(
          investorTransactionImgFile,
          "investments",
          selectedInvestment.campaignTitle,
          uniqueId
        );
        updateData.transactionURL = transactionURL;
      }

      if (status === "APPROVED") {
        const pdfBlob = await generatePDF(receiptRef.current);
        const url = await uploadImage(
          pdfBlob,
          "receipts",
          selectedInvestment.id,
          `RECEIPT_${selectedInvestment.id}`
        );
        updateData.receiptURL = url;
        console.log(url);
      }

      await updateInvestment(id, updateData);
      await createNotification({
        title: selectedInvestmentCampaign.title,
        message:
          status === "APPROVED"
            ? "تم قبول طلب إنضمامك للمحفظة العقارية!"
            : "لم تتم الموافقة على طلب إنضمامك، يمكنك التواصل معنا لمزيد من التفاصيل",
        data: {
          type:
            status === "APPROVED" ? "ACCEPT_INVESTMENT" : "REJECT_INVESTMENT",
        },
        toUser: selectedSuggestedInvestor.id,
        isRead: false,
        createdAt: Timestamp.now(),
      });

      if (status === "APPROVED") {
        const approvedInvestments = investments.filter(
          (inv) =>
            inv.status === "APPROVED" &&
            inv.userId === selectedSuggestedInvestor.id
        );
        // Create a Set to keep track of unique user IDs
        console.log(approvedInvestments);
        if (approvedInvestments.length <= 0) {
          await createNotification({
            title: "المحفظة العقارية",
            message: `تم تغيير قيمة المحفظة من ${walletTotalWithoutHidden.toLocaleString()} ريال إلى ${walletTotal.toLocaleString()} ريال يرجى التحقق من نسبتكم الجديدة في المحفظة`,
            data: { type: "ACCEPT_FIRST_INVESTMENT" },
            toUser: selectedSuggestedInvestor.id,
            isRead: false,
            createdAt: Timestamp.now(),
          });
        }
      }
      navigate(0);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      notify("ERROR", "حصل خطأ أثناء إجراء العملية.");
    }
  };

  const handleUpdateWallet = async () => {
    if (
      walletAnnualROI === "" ||
      walletDuration === "" ||
      capitalRecoveryPeriod === ""
    ) {
      notify("WARNING", "الرجاء ملئ جميع الحقول.");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      await updateSharedInfo({
        walletAnnualROI: walletAnnualROI,
        walletDuration: walletDuration,
        capitalRecoveryPeriod: capitalRecoveryPeriod,
      });
      navigate(0);
    } catch (e) {
      notify("ERROR", "حصل خطأ أثناء تحديث المحفظة العقارية.");
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (Object.keys(selectedCampaign).length === 0) {
      notify("WARNING", "الرجاء اختيار عقار.");
      setIsLoading(false);
      return;
    }
    if (Object.keys(selectedSuggestedUser).length === 0) {
      notify("WARNING", "الرجاء إختيار مستثمر صحيح.");
      setIsLoading(false);
      return;
    }
    if (transactionImgFile === null) {
      notify("WARNING", "الرجاء إضافة صورة الحوالة.");
      setIsLoading(false);
      return;
    }
    try {
      const uniqueId = generateId();
      const transactionURL = await uploadImage(
        transactionImgFile,
        "investments",
        selectedCampaign.title,
        uniqueId
      );
      await createInvestment({
        campaignId: selectedCampaign.id,
        campaignLocationDescription: selectedCampaign.locationDescription,
        campaignShareNumber: Number(selectedCampaign.shareNumber),
        campaignSharePrice: Number(selectedCampaign.sharePrice),
        campaignTitle: selectedCampaign.title,
        notes: "لا يوجد",
        requestedSharesNumber: Number(requestedSharesNumber),
        status: "APPROVED",
        userBankAccounts: selectedSuggestedUser.bankAccounts,
        userFullName: selectedSuggestedUser.name,
        userId: selectedSuggestedUser.id,
        userPhone: selectedSuggestedUser.phone,
        transactionURL: transactionURL,
        receiptURL: "",
        createdAt: Timestamp.now(),
      });
      await createNotification({
        title: selectedCampaign.title,
        message:
          "تمت إضافتك إلى المحفظة العقارية بنجاح، يمكنك عرض التفاصيل في صفحة المحفظة.",
        data: { type: "INVESTMENT_CREATED" },
        toUser: selectedSuggestedUser.id,
        isRead: false,
        createdAt: Timestamp.now(),
      });
      navigate(0);
    } catch (e) {
      console.error("Cannot create investment: ", e);
      notify("ERROR", "حصل خطأ أثناء إنشاء السجل.");
      setIsLoading(false);
    }
  };

  const filterListByStatus = (status) => {
    setSelectedStatus(status);
    if (status === "ALL") {
      setFilteredInvestments(investments);
      return;
    }
    const list = investments.filter((item) => item.status === status);
    setFilteredInvestments(list);
  };

  const receiptRef = useRef();

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />
      <div
        ref={receiptRef}
        style={{ padding: 20 }}
        className="absolute bottom-full"
      >
        <Receipt
          name={investor}
          amountReceived={investorSharePrice}
          date={selectedInvestment.createdAt?.toDate()}
        />
      </div>

      <div className="flex flex-row justify-between items-center mb-10">
        <PageTitle title={"بيانات المحفظة العقارية"} isSubtitle={true} />
        <div className="w-60">
          <CustomButton
            title="تعديل"
            customStyle="p-3"
            handleClick={() => setShowEditWalletModal(true)}
          />
        </div>
      </div>

      <div
        className="rounded-3xl bg-box2-color p-10 mb-10"
        style={{
          backgroundImage: `url(${walletIcon})`,
          backgroundSize: "200px 200px",
          backgroundPosition: "center left 100px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex flex-col items-start gap-10">
          <div className="flex mobile:flex-col laptop:flex-row mobile:gap-5 laptop:gap-20">
            <p className="text-white min-w-[200px]">
              إجمالي قيمة المحفظة العقارية
            </p>
            <p className="text-primary-color flex-grow">
              {walletTotal.toLocaleString()} ريال
            </p>
          </div>
          <div className="flex mobile:flex-col laptop:flex-row mobile:gap-5 laptop:gap-20">
            <p className="text-white min-w-[200px]">العائد السنوي للمحفظة</p>
            <p className="text-primary-color flex-grow">
              {sharedInfo.walletAnnualROI}
            </p>
          </div>
          <div className="flex mobile:flex-col laptop:flex-row mobile:gap-5 laptop:gap-20">
            <p className="text-white min-w-[200px]">مدة المحفظة العقارية</p>
            <p className="text-primary-color flex-grow">
              {sharedInfo.walletDuration}
            </p>
          </div>
          <div className="flex mobile:flex-col laptop:flex-row mobile:gap-5 laptop:gap-20">
            <p className="text-white min-w-[200px]">
              فترة استرداد رأس المال المتوقعة
            </p>
            <p className="text-primary-color flex-grow">
              {sharedInfo.capitalRecoveryPeriod}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between items-center mb-16">
        <PageTitle
          title={"جميع إستثمارات المحفظة العقارية"}
          isSubtitle={true}
        />
        <div className="w-60">
          <CustomButton
            title="إضافة إستثمار +"
            customStyle="p-3"
            handleClick={() => {
              // Reset investor name
              setSelectedSuggestedUser({});
              setShowModal(true);
            }}
          />
        </div>
      </div>
      <div className="grid mobile:grid-cols-2 tablet:grid-cols-4 mb-5 gap-5 w-full">
        <CustomButton
          title="الكل"
          type={selectedStatus === "ALL" ? "default" : "outline"}
          customStyle="p-3"
          handleClick={() => {
            filterListByStatus("ALL");
          }}
        />
        <CustomButton
          title="تمت الموافقة"
          type={selectedStatus === "APPROVED" ? "success" : "success_outline"}
          customStyle="p-3"
          handleClick={() => {
            filterListByStatus("APPROVED");
          }}
        />
        <CustomButton
          title="بانتظار الموافقة"
          type={selectedStatus === "PENDING" ? "regular" : "regular_outline"}
          customStyle="p-3"
          handleClick={() => {
            filterListByStatus("PENDING");
          }}
        />
        <CustomButton
          title="مرفوض"
          type={selectedStatus === "REJECTED" ? "danger" : "danger_outline"}
          customStyle="p-3"
          handleClick={() => {
            filterListByStatus("REJECTED");
          }}
        />
      </div>
      {filteredInvestments.length === 0 ? (
        <h1 className="text-2xl p-10 text-center">لا توجد نتائج</h1>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full text-sm">
            <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
              <tr>
                {tableHeaders.map((header, index) => {
                  return (
                    <th scope="col" className="px-6 py-3" key={index}>
                      {header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="text-center">
              {filteredInvestments.map((column, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                  >
                    {
                      <>
                        <td className="px-6 py-4">{column.userFullName}</td>
                        <td className="px-6 py-4" dir="ltr">
                          {column.userPhone}
                        </td>
                        <td className="px-6 py-4">
                          {(
                            column.requestedSharesNumber *
                            column.campaignSharePrice
                          ).toLocaleString()}{" "}
                          ريال
                        </td>
                        <td className="px-6 py-4">
                          {column.requestedSharesNumber}
                        </td>
                        <td className="px-6 py-4">
                          {column.campaignSharePrice.toLocaleString()} ريال
                        </td>
                        <td className="px-6 py-4">
                          {column.status === "APPROVED" ? (
                            <CustomLabel
                              title={INV_STATUS[column.status]}
                              status="SUCCESS"
                            />
                          ) : column.status === "PENDING" ? (
                            <CustomLabel
                              title={INV_STATUS[column.status]}
                              status="REGULAR"
                            />
                          ) : column.status === "REJECTED" ? (
                            <CustomLabel
                              title={INV_STATUS[column.status]}
                              status="DANGER"
                            />
                          ) : (
                            " - "
                          )}
                        </td>
                        <td className="px-6 py-4">
                          {
                            column.createdAt
                              .toDate()
                              .toISOString()
                              .split("T")[0]
                          }
                        </td>
                        <td className="px-6 py-4">
                          {column.status === "PENDING" ? (
                            <div className="flex gap-5">
                              <CustomButton
                                title="إجراء"
                                customStyle="py-2 px-5"
                                handleClick={async () => {
                                  setSelectedInvestment(column);
                                  const user = await getUserById(column.userId);
                                  setSelectedSuggestedInvestor(user);
                                  setInvestor(column.userFullName);
                                  setInvestorPhoneNumber(column.userPhone);
                                  setInvestorShareNumbers(
                                    column.requestedSharesNumber
                                  );
                                  setInvestorTransactionURL(
                                    column.transactionURL
                                  );
                                  const currentCampaign = campaigns.find(
                                    (camp) => column.campaignId === camp.id
                                  );
                                  setSelectedInvestmentCampaign(
                                    currentCampaign
                                  );
                                  setInvestorSharePrice(
                                    currentCampaign === undefined
                                      ? "لا يوجد (تم حذف الفرصة)"
                                      : column.requestedSharesNumber *
                                          currentCampaign.sharePrice
                                  );
                                  setInvestorNotes(column.notes);
                                  setShowInvestmentModal(true);
                                }}
                              />
                              <CustomButton
                                title="صورة الحوالة"
                                customStyle="py-2 px-5"
                                type="blueGrey"
                                handleClick={() => {
                                  if (!column.transactionURL) {
                                    notify("ERROR", "لاتوجد صورة حوالة.");
                                    return;
                                  }
                                  setInvestorTransactionURL(
                                    column.transactionURL
                                  );
                                  setShowtransactionImgModal(true);
                                }}
                              />
                            </div>
                          ) : (
                            <div className="flex gap-5">
                              <CustomButton
                                title="سند القبض"
                                customStyle="py-2 px-5"
                                type="blue"
                                handleClick={() => {
                                  if (!column.receiptURL) {
                                    notify("ERROR", "لا يوجد سند قبض.");
                                    return;
                                  }
                                  window.open(
                                    column.receiptURL,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }}
                              />
                              <CustomButton
                                title="صورة الحوالة"
                                customStyle="py-2 px-5"
                                type="blueGrey"
                                handleClick={() => {
                                  if (!column.transactionURL) {
                                    notify("ERROR", "لاتوجد صورة حوالة.");
                                    return;
                                  }
                                  setInvestorTransactionURL(
                                    column.transactionURL
                                  );
                                  setShowtransactionImgModal(true);
                                }}
                              />
                            </div>
                          )}
                        </td>
                      </>
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {/* -------------------------------- MODALS ------------------------------------- */}
      <CustomModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="إضافة إستثمار"
        height="h-156"
        content={
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="w-96">
              <div>
                <label className="text-text-2-color font-bold">
                  اختر العقار
                </label>
                <CustomDropdown
                  options={campaigns}
                  customStyle="mt-5 mb-5"
                  defaultValue={selectedCampaign}
                  setValue={setSelectedCampaign}
                  listName="campaigns"
                  isRequired={true}
                  handleCustomChange={(e) => {
                    setSharesPrice(
                      selectedCampaign.sharePrice * requestedSharesNumber
                    );
                  }}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">المستثمر</label>
                <CustomInput
                  type="text"
                  value={investorName}
                  setValue={setInvestorName}
                  placeholder="اكتب اسم المستثمر"
                  customStyle={`mt-5 mb-5 ${
                    Object.keys(selectedSuggestedUser).length !== 0
                      ? "bg-success-color text-white"
                      : ""
                  }`}
                  hasSuggestions={true}
                  suggestions={suggestions}
                  setSuggestions={setSuggestions}
                  setSelectedSuggestion={setSelectedSuggestedUser}
                  isRequired={true}
                  handleCustomAction={async (e) => {
                    const value = e.target.value.trim("");
                    if (value.length === 0) {
                      setSuggestions([]);
                      setSelectedSuggestedUser({});
                    } else {
                      const arr = [];
                      const users = await getUserByName(value);
                      users.map((user) => arr.push(user));
                      const returnedArray = arr.filter((u) => u.name === value);
                      if (returnedArray.length === 0) {
                        setSelectedSuggestedUser({});
                      }
                      setSuggestions(arr);
                    }
                    console.log("Selected: ", selectedSuggestedUser);
                  }}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">عدد الحصص</label>
                <CustomInput
                  type="number"
                  value={requestedSharesNumber}
                  setValue={setRequestedSharesNumber}
                  placeholder="ادخل عدد الحصص..."
                  customStyle="mt-5 mb-5"
                  handleCustomAction={(e) => {
                    setSharesPrice(
                      selectedCampaign.sharePrice * e.target.value
                    );
                  }}
                  isRequired={true}
                  isDisabled={Object.keys(selectedCampaign).length === 0}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  قيمة الحصص
                </label>
                <CustomInput
                  type="text"
                  value={`${sharesPrice.toLocaleString()} ريال`}
                  setValue={setSharesPrice}
                  placeholder="ادخل قيمة الحصص"
                  isDisabled={true}
                  customStyle="mt-5 mb-10"
                  isRequired={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  صورة الحوالة
                </label>
                <div className="my-5">
                  <ImagePicker
                    selectedImage={transactionImgFile}
                    setSelectedImage={setTransactionImgFile}
                  />
                </div>
              </div>
              <CustomButton
                title="إضافة"
                customStyle="py-5"
                isLoading={isLoading}
              />
            </div>
          </form>
        }
      />

      <CustomModal
        showModal={showEditWalletModal}
        setShowModal={setShowEditWalletModal}
        title="تعديل بيانات المحفظة العقارية"
        height="h-156"
        content={
          <div className="w-96">
            <div>
              <label className="text-text-2-color font-bold">
                إجمالي قيمة المحفظة العقارية
              </label>
              <CustomInput
                type="text"
                value={walletTotal.toLocaleString()}
                setValue={setWalletTotal}
                placeholder="0"
                customStyle="mt-5 mb-5"
                isRequired={true}
                isDisabled={true}
              />
            </div>

            <div>
              <label className="text-text-2-color font-bold">
                العائد السنوي للمحفظة
              </label>
              <CustomInput
                type="text"
                value={walletAnnualROI}
                setValue={setWalletAnnualROI}
                placeholder="ادخل العائد السنوي للمحفظة..."
                customStyle="mt-5 mb-10"
                isRequired={true}
              />
            </div>

            <div>
              <label className="text-text-2-color font-bold">
                مدة المحفظة العقارية
              </label>
              <CustomInput
                type="text"
                value={walletDuration}
                setValue={setWalletDuration}
                placeholder="ادخل مدة المحفظة العقارية..."
                customStyle="mt-5 mb-10"
                isRequired={true}
              />
            </div>

            <div>
              <label className="text-text-2-color font-bold">
                فترة استرداد رأس المال المتوقعة
              </label>
              <CustomInput
                type="text"
                value={capitalRecoveryPeriod}
                setValue={setCapitalRecoveryPeriod}
                placeholder="ادخل فترة استرداد رأس المال المتوقعة..."
                customStyle="mt-5 mb-10"
                isRequired={true}
              />
            </div>

            <CustomButton
              title="تحديث"
              customStyle="py-5"
              isLoading={isLoading}
              handleClick={handleUpdateWallet}
            />
          </div>
        }
      />

      <CustomModal
        showModal={showInvestmentModal}
        setShowModal={setShowInvestmentModal}
        title="عرض الإستثمار"
        height="h-156"
        content={
          <div>
            <div className="flex gap-5">
              <div className="w-full">
                <label className="text-text-2-color font-bold">المستثمر</label>
                <CustomInput
                  type="text"
                  value={investor}
                  setValue={setInvestor}
                  placeholder="اكتب اسم المستثمر"
                  customStyle={`mt-5 mb-5 ${
                    Object.keys(selectedSuggestedInvestor).length !== 0
                      ? "bg-success-color text-white"
                      : ""
                  }`}
                  hasSuggestions={true}
                  suggestions={suggestions}
                  setSuggestions={setSuggestions}
                  setSelectedSuggestion={setSelectedSuggestedInvestor}
                  setNumber={setInvestorPhoneNumber}
                  isRequired={true}
                  handleCustomAction={async (e) => {
                    const value = e.target.value.trim("");
                    if (value.length === 0) {
                      setSuggestions([]);
                      setSelectedSuggestedInvestor({});
                    } else {
                      const arr = [];
                      const users = await getUserByName(value);
                      users.map((user) => arr.push(user));
                      const returnedArray = arr.filter((u) => u.name === value);
                      if (returnedArray.length === 0) {
                        setSelectedSuggestedInvestor({});
                      }
                      setSuggestions(arr);
                    }
                  }}
                />
              </div>

              <div className="w-full">
                <label className="text-text-2-color font-bold">
                  رقم الجوال
                </label>
                <CustomInput
                  type="text"
                  value={investorPhoneNumber}
                  setValue={setInvestorPhoneNumber}
                  placeholder="..."
                  customStyle="mt-5 mb-10"
                  isRequired={true}
                  isDisabled={true}
                />
              </div>
            </div>

            <div className="flex gap-5">
              <div className="w-full">
                <label className="text-text-2-color font-bold">عدد الحصص</label>
                <CustomInput
                  type="text"
                  value={investorShareNumbers}
                  setValue={setInvestorShareNumbers}
                  placeholder="0"
                  customStyle="mt-5 mb-5"
                  isRequired={true}
                  handleCustomAction={(e) => {
                    setInvestorSharePrice(
                      e.target.value * selectedInvestmentCampaign.sharePrice
                    );
                  }}
                />
              </div>

              <div className="w-full">
                <label className="text-text-2-color font-bold">
                  قيمة الحصص (ريال)
                </label>
                <CustomInput
                  type="text"
                  value={investorSharePrice.toLocaleString()}
                  setValue={setInvestorSharePrice}
                  placeholder="..."
                  customStyle="mt-5 mb-10"
                  isRequired={true}
                  isDisabled={true}
                />
              </div>
            </div>

            <label className="text-text-2-color font-bold">ملاحظات</label>
            <CustomTextArea
              type="text"
              value={investorNotes}
              setValue={setInvestorNotes}
              placeholder="..."
              customStyle="mt-5 mb-5 h-36"
              isRequired={true}
            />

            <label className="text-text-2-color font-bold">
              صورة من الحوالة
            </label>
            <p className="text-text-2-color mt-5">
              في حال لم يتم رفع الصورة من المستثمر في التطبيق الرجاء مراجعة
              محادثات الواتس لجلب الصورة ورفعها هنا لقبول الإستثمار
            </p>
            <div className="my-5">
              <ImagePicker
                selectedImage={investorTransactionImgFile}
                setSelectedImage={setInvestorTransactionImgFile}
                imgUrl={investorTransactionURL}
                setImgUrl={setInvestorTransactionURL}
              />
            </div>

            <div className="flex gap-5">
              <CustomButton
                title="موافقة"
                customStyle="py-5"
                type="primary"
                isLoading={isLoading}
                handleClick={() => {
                  handleInvestmentStatus(selectedInvestment.id, "APPROVED");
                }}
              />
              <CustomButton
                title="رفض"
                customStyle="py-5"
                type="danger"
                isLoading={isLoading}
                handleClick={() => {
                  handleInvestmentStatus(selectedInvestment.id, "REJECTED");
                }}
              />
            </div>
          </div>
        }
      />

      <CustomModal
        showModal={showTransactionImgModal}
        setShowModal={setShowtransactionImgModal}
        title="صورة الحوالة"
        height="h-156"
        content={
          // Usage example
          <ImagePreview
            src={investorTransactionURL}
            alt="Preview"
            className="w-full h-full m-auto"
          />
        }
      />
    </div>
  );
}
